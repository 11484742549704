import {
	isLocalEnvironment,
	isVercelDevEnvironment,
	shouldSentryBeEnabled,
} from "$lib/utils/test/debugging";
import { replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
	dsn: "https://de17081a7422f9ec0666c8979a4bb028@o1284106.ingest.sentry.io/4506576650305536",
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: isVercelDevEnvironment(PUBLIC_VERCEL_ENV)
		? 0.1
		: 1.0,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
		}),
	],
	environment: isVercelDevEnvironment(PUBLIC_VERCEL_ENV)
		? "development"
		: "production",
	enabled: shouldSentryBeEnabled(PUBLIC_VERCEL_ENV),
});

export async function handleError(context) {
	const { error, event } = context;

	if (isLocalEnvironment()) {
		console.error("internal error:", error);
	}

	Sentry.captureException(error, {
		extra: {
			event,
			context: {
				accountId: null,
			},
		},
	});
}
