import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96')
];

export const server_loads = [0,2,27,28,25,3,7,8,10,11,5,6,13,14,19,16,21];

export const dictionary = {
		"/(public)": [~90,[26]],
		"/(private)/create-account": [~80,[2,23]],
		"/(public)/guest": [95,[26]],
		"/(public)/(premium)/guides": [91,[26,27,28],[,,29]],
		"/(public)/(premium)/guides/[slug]": [~92,[26,27,28,30],[,,29]],
		"/(private)/help": [~81,[2,24]],
		"/(private)/home": [82,[2]],
		"/(public)/login": [96,[26,33]],
		"/(private)/profile": [~83,[2,25]],
		"/(private)/profile/accounts": [84,[2,25]],
		"/(private)/profile/authentication": [85,[2,25]],
		"/(private)/profile/invites": [86,[2,25]],
		"/(private)/profile/invites/[inviteId]": [~87,[2,25]],
		"/(private)/profile/lock": [~88,[2,25]],
		"/(private)/sentry-debug-client": [89,[2]],
		"/(public)/(premium)/tutorials": [~93,[26,27,31]],
		"/(public)/(premium)/tutorials/[slug]": [~94,[26,27,31,32]],
		"/(private)/[accountId]": [~34,[2,3],[,4]],
		"/(private)/[accountId]/activity": [63,[2,3,17],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/devices": [38,[2,3,7,8,9,10],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns": [~39,[2,3,7,8,9,11],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns/popular": [40,[2,3,7,8,9,11],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns/recent": [41,[2,3,7,8,9,11],[,4]],
		"/(private)/[accountId]/activity/history": [64,[2,3,17],[,4]],
		"/(private)/[accountId]/(checkout)/checkout/stripe": [~35,[2,3,5,6],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy": [~42,[2,3,7,8,12],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect": [~43,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/android": [~44,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/android/app": [45,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/app/prevent-bypass": [46,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/ios": [~47,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/ios/app": [48,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/mac": [~49,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/mac/app": [50,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/router": [~51,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/windows": [~52,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/windows/app": [53,[2,3,7,8,12,13],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/rules/[ruleId]": [~54,[2,3,7,8,12,14],[,4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/rules/[ruleId]/history": [~55,[2,3,7,8,12,14],[,4]],
		"/(private)/[accountId]/(checkout)/select-plan": [36,[2,3,5],[,4]],
		"/(private)/[accountId]/settings": [65,[2,3,18],[,4]],
		"/(private)/[accountId]/settings/billing": [~66,[2,3,18,19],[,4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)": [~56,[2,3,7,15,16],[,4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/agent": [~57,[2,3,7,15,16],[,4]],
		"/(private)/[accountId]/settings/cloudflare/connect": [~67,[2,3,20],[,4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/debug": [~58,[2,3,7,15,16],[,4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/networks": [~59,[2,3,7,15,16],[,4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/networks/location": [~60,[2,3,7,15,16],[,4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/sync": [~62,[2,3,7,15],[,4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/traffic": [~61,[2,3,7,15,16],[,4]],
		"/(private)/[accountId]/settings/members": [~68,[2,3,18],[,4]],
		"/(private)/[accountId]/setup": [~69,[2,3,21],[,4]],
		"/(private)/[accountId]/setup/ios": [~70,[2,3,21],[,4]],
		"/(private)/[accountId]/setup/ios/prevent-bypass": [71,[2,3,21],[,4]],
		"/(private)/[accountId]/setup/ios/prevent-bypass/supervised": [72,[2,3,21],[,4]],
		"/(private)/[accountId]/(checkout)/success/stripe": [~37,[2,3,5],[,4]],
		"/(private)/[accountId]/tools": [73,[2,3,22],[,4]],
		"/(private)/[accountId]/tools/ios": [74,[2,3,22],[,4]],
		"/(private)/[accountId]/tools/ios/app-management": [75,[2,3,22],[,4]],
		"/(private)/[accountId]/tools/ios/config-generator/custom": [76,[2,3,22],[,4]],
		"/(private)/[accountId]/tools/ios/config-presets": [77,[2,3,22],[,4]],
		"/(private)/[accountId]/tools/mac": [78,[2,3,22],[,4]],
		"/(private)/[accountId]/tools/mac/content-policy-enforcer": [79,[2,3,22],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';